<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      width="12.962"
      height="12.962"
      rx="2"
    />
    <path
      d="M12.1511 16H5.6701C5.33322 15.9956 5.06247 15.7212 5.0625 15.3843C5.06693 15.0519 5.33767 14.7848 5.6701 14.7848H12.1511C12.8508 14.7848 13.5217 14.5063 14.0157 14.0108C14.5097 13.5153 14.7862 12.8435 14.784 12.1438V5.66277C14.784 5.32721 15.0561 5.05518 15.3916 5.05518C15.7272 5.05518 15.9992 5.32721 15.9992 5.66277V12.1438C15.9992 14.2703 14.2777 15.9955 12.1511 16Z"
    />
  </svg>
</template>
